import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  .user-management-page-container {
    max-width: 1240px;
    height: 100%;
    margin: 0 auto;
    padding-top: 30px;
    .user-management-title-section-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 55px;
      margin-bottom: 40px;
      .title {
        font-family: 'Prompt', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: bold;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        margin-right: 25px;
      }
      .create-new-user-btn {
        width: 160px;
        font-size: 14px;
      }
    }
  }
`
