/* eslint-disable no-useless-escape */
/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as UserManagementActions from './actions'

import Button from '../../components/Button/Loadable'
import TextInput from '../../components/TextInput/Loadable'
import ModalOverlay from '../../components/ModalOverlay/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import Dropdown from '../../components/Dropdown/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import ManageUserBox from '../../components/ManageUserBox/Loadable'

import fetchErrorHandler from '../../utils/fetchErrorHandler'

import UserManagementPageStyled from './styledComponent'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, FRONTEND_ROUTES } from '../../utils'

import MESSAGE from './message'

export const ADMIN = 'admin'
export const MANAGER = 'manager'
export const VIEWER = 'viewer'
const adminOptions = [
  { text: 'Administrator', value: ADMIN },
  { text: 'CPF Administrator', value: MANAGER },
  { text: 'Viewer', value: VIEWER }
]
const COLLABORATOR_AMOUNT = 5

export class UserManagementPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoading: false,
      showCreateNewUserModal: false,
      newUserData: {
        email: '',
        role: '',
        project: ''
      },
      isNewUserEmailValid: undefined,
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    }
  }

  componentDidMount() {
    this.props.loadProjectUserList()
  }

  componentDidUpdate(prevProps, prevState) {
    this._handleFetchLoadProjectUserList(prevProps)
    this._handleFetchCreateNewUser(prevProps)
    this._handleFetchCreateNewAdmin(prevProps)
    this._handleFetchCreateNewManager(prevProps)
    this._handleFetchUpdateUserAccount(prevProps)
    this._handleFetchDeleteUserAccount(prevProps)
    this._checkPermission()
  }

  _checkPermission() {
    const isAdmin = this.props.currentUser.admin
    const isManager = this.props.currentUser.manager
    const isUser = !(isAdmin || isManager)
    if (isUser) {
      this.props.history.push(FRONTEND_ROUTES.selectSiteRoute)
    }
  }

  _handleFetchDeleteUserAccount(prevProps) {
    if (
      prevProps.userManagementPage.deleteUserAccount.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.userManagementPage.deleteUserAccount.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.userManagementPage.deleteUserAccount.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.deleteUserAccount.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
      this.props.loadProjectUserList()
    }
    if (
      prevProps.userManagementPage.deleteUserAccount.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.deleteUserAccount.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleDeleteUserAccountFailure()
    }
  }

  _handleFetchLoadProjectUserList(prevProps) {
    if (
      prevProps.userManagementPage.loadProjectUserList.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.userManagementPage.loadProjectUserList.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.userManagementPage.loadProjectUserList.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.loadProjectUserList.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.userManagementPage.loadProjectUserList.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.loadProjectUserList.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleLoadProjectUserFailure()
    }
  }

  _handleFetchCreateNewUser(prevProps) {
    if (
      prevProps.userManagementPage.createNewUser.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.userManagementPage.createNewUser.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.userManagementPage.createNewUser.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.createNewUser.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
      this.props.loadProjectUserList()
    }
    if (
      prevProps.userManagementPage.createNewUser.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.createNewUser.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleCreateNewUserFailure()
    }
  }

  _handleFetchCreateNewAdmin(prevProps) {
    if (
      prevProps.userManagementPage.createNewAdmin.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.userManagementPage.createNewAdmin.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.userManagementPage.createNewAdmin.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.createNewAdmin.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
      this.props.loadProjectUserList()
    }
    if (
      prevProps.userManagementPage.createNewAdmin.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.createNewAdmin.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleCreateNewAdminFailure()
    }
  }

  _handleFetchCreateNewManager(prevProps) {
    if (
      prevProps.userManagementPage.createNewManager.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.userManagementPage.createNewManager.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.userManagementPage.createNewManager.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.createNewManager.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
      this.props.loadProjectUserList()
    }
    if (
      prevProps.userManagementPage.createNewManager.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.createNewManager.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleCreateNewManagerFailure()
    }
  }

  _handleFetchUpdateUserAccount(prevProps) {
    if (
      prevProps.userManagementPage.updateUserAccount.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.userManagementPage.updateUserAccount.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.userManagementPage.updateUserAccount.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.updateUserAccount.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
      this.props.loadProjectUserList()
    }
    if (
      prevProps.userManagementPage.updateUserAccount.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagementPage.updateUserAccount.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleUpdateUserAccountFailure()
    }
  }

  forceLogin = () => {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  handleUpdateUserAccountFailure() {
    const statusCode = this.props.userManagementPage.updateUserAccount.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleLoadProjectUserFailure() {
    const statusCode = this.props.userManagementPage.loadProjectUserList.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleDeleteUserAccountFailure() {
    const statusCode = this.props.userManagementPage.deleteUserAccount.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleCreateNewUserFailure() {
    const statusCode = this.props.userManagementPage.createNewUser.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin,
        422: this.showDuplicateEmail
      },
      this.showDefaultFailureModal
    )
  }

  handleCreateNewAdminFailure() {
    const statusCode = this.props.userManagementPage.createNewAdmin.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin,
        422: this.showDuplicateEmail
      },
      this.showDefaultFailureModal
    )
  }

  handleCreateNewManagerFailure() {
    const statusCode = this.props.userManagementPage.createNewManager.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin,
        422: this.showDuplicateEmail
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  showDuplicateEmail = () => {
    this.setState({
      showCreateNewUserModal: true,
      isNewUserEmailValid: false
    })
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }
  setShowLoading(status) {
    this.setState({
      showLoading: status
    })
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser.language]
    return msg[property]
  }

  setShowCreateNewUserModal = () => {
    this.setState({
      showCreateNewUserModal: true,
      newUserData: {
        email: '',
        role: this.props.currentUser.admin ? '' : { text: 'Viewer', value: VIEWER },
        project: ''
      }
    })
  }

  getUserManagementTitleSection() {
    return (
      <div className="user-management-title-section-container">
        <div className="title">{this.getMessage('user_management')}</div>
        <Button
          id="create-new-user-btn"
          className="create-new-user-btn"
          onClick={this.setShowCreateNewUserModal}
          text={this.getMessage('create_new_user_btn_txt')}
        />
      </div>
    )
  }

  handleCloseCreateNewUserModal = () => {
    this.setState({
      showCreateNewUserModal: false,
      isNewUserEmailValid: undefined
    })
  }

  _isCreateUserInputValid = () => {
    const { email, role, project } = this.state.newUserData
    const isNewUserEmailValid = this._isEmailValid(email.trim())
    const isRoleValid = !!role
    const isProjectValid = role.value === MANAGER ? project.length > 0 : !!project
    const isCreateAdmin = role.value === ADMIN
    let isViewerAmountValid = true
    if (this.state.newUserData.role.value === VIEWER) {
      const selectedProjectData = this.props.userManagementPage.listProject.find(
        (projectData) => this.state.newUserData.project && projectData.project_id === this.state.newUserData.project.value
      )
      isViewerAmountValid = selectedProjectData ? selectedProjectData.users.length < COLLABORATOR_AMOUNT : false
    }
    return isNewUserEmailValid && isRoleValid && (isProjectValid || isCreateAdmin) && isViewerAmountValid
  }

  preparedPayload() {
    let payload = {}
    const { email, role, project } = this.state.newUserData
    if (role.value === ADMIN) {
      payload = {
        admin: {
          email: email.trim(),
          firstname: 'Sertis',
          lastname: 'Sertis'
        }
      }
    } else if (role.value === MANAGER) {
      payload = {
        account: {
          email: email.trim(),
          firstname: 'Sertis',
          lastname: 'Sertis'
        },
        project_ids: project.map((data) => data.value)
      }
    } else {
      payload = {
        account: {
          email: email.trim(),
          project_id: project.value,
          firstname: 'Sertis',
          lastname: 'Sertis'
        }
      }
    }
    return payload
  }

  handleCreateNewUserBtnClicked = () => {
    if (this._isCreateUserInputValid()) {
      const { role } = this.state.newUserData
      const data = this.preparedPayload()
      if (role.value === ADMIN) {
        this.props.createNewAdmin(data)
      } else if (role.value === MANAGER) {
        this.props.createNewManager(data)
      } else {
        this.props.createNewUser(data)
      }
      this.handleCloseCreateNewUserModal()
    }
  }

  _isEmailValid(email) {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexEmail.test(email)
  }

  handleNewUserRoleChanged = (data) => {
    this.setState({
      newUserData: Object.assign({}, this.state.newUserData, {
        role: data,
        project: data.value === MANAGER ? [] : ''
      })
    })
  }

  getRoleDropdownContainer() {
    let output = null
    if (this.props.currentUser.admin) {
      output = (
        <div className="modal-user-management-row-container">
          <Dropdown
            className="role-dropdown"
            label="ROLE"
            labelPosition="top"
            options={adminOptions}
            onChange={this.handleNewUserRoleChanged}
            value={this.state.newUserData.role}
            language={this.props.currentUser.language}
            buttonColor="#27a448"
          />
        </div>
      )
    }
    return output
  }

  handleNewUserProjectChanged = (data) => {
    const isSelectedRoleManager = this.state.newUserData.role.value === MANAGER
    this.setState({
      newUserData: Object.assign({}, this.state.newUserData, {
        project: isSelectedRoleManager ? [...this.state.newUserData.project, data] : data
      })
    })
  }

  handleRemoveSelectedProject = (option) => {
    this.setState({
      newUserData: Object.assign({}, this.state.newUserData, {
        project: this.state.newUserData.project.filter((data) => data.value !== option.value)
      })
    })
  }

  handleClearAllSelectedProject = () => {
    this.setState({
      newUserData: Object.assign({}, this.state.newUserData, {
        project: []
      })
    })
  }

  preparedListProjectOptions() {
    const listAllProjectOptions = this.props.userManagementPage.listProject.map((data) => {
      return {
        text: data.name,
        value: data.project_id
      }
    })
    let projectOptions = listAllProjectOptions
    if (this.state.newUserData.role.value === MANAGER) {
      projectOptions = projectOptions.filter((data) => {
        const selected = this.state.newUserData.project.find((obj) => obj.value === data.value)
        return !selected
      })
    }
    return projectOptions
  }

  getProjectDropdownContainer() {
    let output = null
    if (this.state.newUserData.role.value !== ADMIN) {
      const listProjectOptions = this.preparedListProjectOptions()
      output = (
        <div className="modal-user-management-row-container">
          <Dropdown
            className="project-dropdown"
            label="PROJECT"
            labelPosition="top"
            options={listProjectOptions}
            onChange={this.handleNewUserProjectChanged}
            onRemoveOption={this.handleRemoveSelectedProject}
            onClearAll={this.handleClearAllSelectedProject}
            value={this.state.newUserData.project}
            language={this.props.currentUser.language}
            buttonColor="#27a448"
            multiSelect={this.state.newUserData.role.value === MANAGER}
          />
        </div>
      )
    }
    return output
  }

  handleNewUserEmailChanged = (e, newValue) => {
    this.setState({
      newUserData: Object.assign({}, this.state.newUserData, {
        email: newValue
      })
    })
  }

  getCreateNewUserErrorText() {
    let output = ''
    if (this.state.isNewUserEmailValid === false) {
      output = this.getMessage('create_new_user_dialog_email_error_txt')
    }
    return output
  }

  getCreateNewUserModal() {
    let output = null
    if (this.state.showCreateNewUserModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseCreateNewUserModal}>
          <div className="modal-user-management-container">
            <div className="modal-user-management-header-container">{this.getMessage('create_new_user_dialog_title_txt')}</div>
            <div className="modal-user-management-row-container">
              <TextInput
                id="user-detail-email"
                placeHolder={this.getMessage('create_new_user_dialog_email_placeholder')}
                label={this.getMessage('create_new_user_dialog_email_label')}
                value={this.state.newUserData.email}
                onChange={this.handleNewUserEmailChanged}
                mode="material-design"
                errorText={this.getCreateNewUserErrorText()}
              />
            </div>
            {this.getRoleDropdownContainer()}
            {this.getProjectDropdownContainer()}
            <div className="modal-user-management-btn-container">
              <Button
                id="create-new-user-btn"
                className="modal-user-management-btn"
                onClick={this.handleCreateNewUserBtnClicked}
                text={this.getMessage('create_new_user_dialog_add_btn')}
                disabled={!this._isCreateUserInputValid()}
              />
            </div>
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  handleRemoveUser = (userID) => {
    this.props.deleteUserAccount(userID)
  }

  handleUpdateUser = (userID, selectedProject) => {
    let data = {
      project_id: selectedProject.value
    }
    if (Array.isArray(selectedProject)) {
      data = {
        project_ids: selectedProject.map((data) => data.value)
      }
    }
    this.props.updateUserAccount(data, userID)
  }

  getUserManagementList() {
    return (
      <div className="user-management-list-container">
        <ManageUserBox
          listAdmin={this.props.userManagementPage.listAdmin}
          listManager={this.props.userManagementPage.listManager}
          listProject={this.props.userManagementPage.listProject}
          onUpdateUser={this.handleUpdateUser}
          onRemoveUser={this.handleRemoveUser}
          currentUser={this.props.currentUser}
          language={this.props.currentUser.language}
        />
      </div>
    )
  }

  getLoading() {
    let output = null
    if (this.state.showLoading) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <UserManagementPageStyled>
        <div className="user-management-page-container">
          {this.getUserManagementTitleSection()}
          {this.getUserManagementList()}
        </div>
        {this.getCreateNewUserModal()}
        {this.getLoading()}
        {this.getFailureModal()}
      </UserManagementPageStyled>
    )
  }
}

UserManagementPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  userManagementPage: PropTypes.object.isRequired,
  loadProjectUserList: PropTypes.func.isRequired,
  createNewAdmin: PropTypes.func.isRequired,
  createNewManager: PropTypes.func.isRequired,
  createNewUser: PropTypes.func.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  updateUserAccount: PropTypes.func.isRequired
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    currentUser: state.loginPage.loginAPI.userData,
    userManagementPage: state.userManagementPage
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, UserManagementActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementPage)
