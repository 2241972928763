const MESSAGE = {
  EN: {
    user_management: 'User Management',
    create_new_user_btn_txt: 'Add New User',
    create_new_user_dialog_title_txt: 'New User Details',
    create_new_user_dialog_email_label: 'EMAIL',
    create_new_user_dialog_email_error_txt: 'Email is already been used.',
    create_new_user_dialog_email_placeholder: 'Enter User Email here',
    create_new_user_dialog_project_title: 'PROJECT',
    create_new_user_dialog_add_btn: 'Add',
    project: 'PROJECT',
    error_dialog_title_duplicate_email: 'Duplicate Email',
    error_dialog_message_duplicate_email: 'This is email has been used.',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    user_management: 'จัดการรายชื่อ',
    create_new_user_btn_txt: 'สร้างรายชื่อใหม่',
    create_new_user_dialog_title_txt: 'ข้อมูลผู้ใช้',
    create_new_user_dialog_email_label: 'อีเมล',
    create_new_user_dialog_email_error_txt: 'อีเมลถูกใช้แล้ว',
    create_new_user_dialog_email_placeholder: 'ใส่อีเมลผู้ใช้ตรงนี้',
    create_new_user_dialog_project_title: 'โปรเจกต์',
    create_new_user_dialog_add_btn: 'เพิ่ม',
    project: 'โปรเจกต์',
    error_dialog_title_duplicate_email: 'อีเมลซ้ำ',
    error_dialog_message_duplicate_email: 'อีเมลนี้ถูกใช้แล้วในระบบ',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE
